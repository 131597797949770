jQuery(document).ready(function($) {
  /* ====================== AOS ======================  */
  AOS.init();
  /* ====================== Mobile Menu ======================  */
  $('.toggle-cntainVilla').click(function() {
    $(this).toggleClass('active_mobTog');
    $('body').toggleClass('noScrolvillF');
    $('#villa_mobOverlay').toggleClass('open');
  });

  /* ====================== Sticky Nav ======================  */
  $(window).scroll(function() {
    var scroll = $(window).scrollTop();
    // move callback button //
    if (scroll >= 998) {
      $('#stickyHdr_villa').addClass('activeStickyHdr_vil');
    } else {
      $('#stickyHdr_villa').removeClass('activeStickyHdr_vil');
    }
  });

  /* ====================== Overlays ======================  */
  // video embed //
  $('.plembedvideo').click(function() {
    $('body').addClass('noScrolvillF');
    $('#videoOverlayerCb').addClass('activatedOverlayerCB');
  });
  $('.closeOverlayerVid').click(function() {
    $('body').removeClass('noScrolvillF');
    $('#videoOverlayerCb').removeClass('activatedOverlayerCB');
    $('#resppEmbedVideo').each(function(index) {
      $(this).attr('src', $(this).attr('src'));
      return false;
    });
  });
  /* ====================== Carousels ======================  */
  // all devs slider //
  $('#slideAlldevs_vil').owlCarousel({
    loop:true,
    margin:15,
    nav:false,
    dots: false,
    mouseDrag: true,
    touchDrag: false,
    responsive : {
      0 : {
          items:1
      },
      768 : {
          items:2
      }
    }
  });
  // controls all devs //
  var alldevsslider = $('#slideAlldevs_vil');
  alldevsslider.owlCarousel();
  $('.movealldevright').click(function() {
    alldevsslider.trigger('next.owl.carousel');
  })
  $('.movealldevleft').click(function() {
    alldevsslider.trigger('prev.owl.carousel');
  });
  // hp slider //
  $('#hp_galleryVil').owlCarousel({
    loop:true,
    margin:15,
    nav:false,
    dots: false,
    mouseDrag: true,
    touchDrag: false,
    responsive : {
      0 : {
          items:1
      },
      768 : {
          items:2
      }
    }
  });
  // controls hp slide //
  var hpGalConrol = $('#hp_galleryVil');
  hpGalConrol.owlCarousel();
  $('.nextHpGal').click(function() {
    hpGalConrol.trigger('next.owl.carousel');
  })
  $('.prevHpGal').click(function() {
    hpGalConrol.trigger('prev.owl.carousel');
  });
  var hpGalConrolMob = $('#hp_galleryVil');
  hpGalConrolMob.owlCarousel();
  $('.galLeft').click(function() {
    hpGalConrol.trigger('next.owl.carousel');
  })
  $('.galRight').click(function() {
    hpGalConrol.trigger('prev.owl.carousel');
  });
  // previous devs //
  $('.prevSliderGall').owlCarousel({
    loop:true,
    margin:15,
    nav:false,
    mouseDrag: true,
    touchDrag: false,
    dots: false,
    responsive : {
      0 : {
          items:2
      },
      768 : {
          items:3
      }
    }
  });
  // prev gallery click //
  $('.itemWrapp').click(function() {
    var bgChanger = $(this).attr('data-thisimgu');
    var bgChangeIdentify = $(this).attr('data-identify');
    $('.feature_prevIdentify_'+bgChangeIdentify).css({'background-image':'url('+bgChanger+')'});
  });
  // prev gal controls //
  var prevGalControl = $('.prevSliderGall');
  prevGalControl.owlCarousel();
  $('.nextPrevGall').click(function() {
    prevGalControl.trigger('next.owl.carousel');
  })
  $('.prevPrevGall').click(function() {
    prevGalControl.trigger('prev.owl.carousel');
  });
  // prev gal controls mobile //
  var prevGalControlMob = $('.prevSliderGall');
  prevGalControl.owlCarousel();
  $('.nextPrevGallMob').click(function() {
    prevGalControl.trigger('next.owl.carousel');
  })
  $('.prevPrevGallMob').click(function() {
    prevGalControl.trigger('prev.owl.carousel');
  });
  // development gallery //
  $('#dev_galleryM').owlCarousel({
    loop:true,
    nav:false,
    dots: false,
    mouseDrag: true,
    touchDrag: false,
    margin:15,
    responsive : {
      0 : {
        items:1
      },
      768 : {
        stagePadding: 100,
        items: 1,
      },
      1200 : {
        stagePadding: 195,
        items: 1,
      }
    }
  });
  // conrols dev gal //
  var allGallDev = $('#dev_galleryM');
  allGallDev.owlCarousel();
  $('.moveallGallleft').click(function() {
    allGallDev.trigger('prev.owl.carousel');
  })
  $('.moveallGallright').click(function() {
    allGallDev.trigger('next.owl.carousel');
  });
  var allgallslider = $('#dev_galleryM');
  alldevsslider.owlCarousel();
  $('.movedevgalleryleft').click(function() {
    allgallslider.trigger('prev.owl.carousel');
  })
  $('.movedevgalleryright').click(function() {
    allgallslider.trigger('next.owl.carousel');
  });
  // banner slider //
  $('#slider_bnr_villa').owlCarousel({
    loop:true,
    margin:0,
    autoplay:true,
    autoplayTimeout: 7500,
    autoplayHoverPause:true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    touchDrag  : false,
    mouseDrag  : false,
    nav:false,
    dots: false,
    items:1,
    onInitialized  : countersliders,
    onTranslated : countersliders
  });
  // banner slider count //
  function countersliders(event) {
    if (!event.namespace) {
      return;
    }
    // vars //
    var slides = event.relatedTarget;
    var currentSlide = slides.relative(slides.current());
    var showCurrent = currentSlide + 1;
    $('.slider-counterVil').html('0'+showCurrent+ ' / 0' + slides.items().length );
  }
  /* ====================== Availability ======================  */
  $('.avvSwitcher_toRent').click(function() {
    $('.rental_property_villa').removeClass('hideThisrow_vilAvv');
    $('.buying_property_villa').addClass('hideThisrow_vilAvv');
    $(this).addClass('activeViewbtnPlt');
    $('.avvSwitcher_toBuy').removeClass('activeViewbtnPlt');
  });
  $('.avvSwitcher_toBuy').click(function() {
    $('.rental_property_villa').addClass('hideThisrow_vilAvv');
    $('.buying_property_villa').removeClass('hideThisrow_vilAvv');
    $(this).addClass('activeViewbtnPlt');
    $('.avvSwitcher_toRent').removeClass('activeViewbtnPlt');
  });
  /* ====================== Siteplan ======================  */
  // Draggable Zoom map //
  if( $('#siteplan_wrap').length ) {
    window.panZoom = svgPanZoom('#siteplan_wrap svg', {
      zoomEnabled: true,
      mouseWheelZoomEnabled: false,
      controlIconsEnabled: false,
      zoomScaleSensitivity: 0.25,
      maxZoom: 3,
      minZoom: 1.25,
      contain: true
    });
    // Zoom by 125% on intialise //
    panZoom.zoomBy(1.25);
    // Buttons for zoom //
      // Zoom in //
      document.getElementById('zoom-in').addEventListener('click', function(ev){
        ev.preventDefault();
        jQuery('#interactive_infopopup').removeClass('showinteractive_infopop');
        panZoom.zoomIn();
        // Zoom vars //
        var currenrtZoom = panZoom.getZoom();
        var highestZoom = Math.round(currenrtZoom);
        var lowestZoom = currenrtZoom.toFixed(3);
        // Max zoom in hit - disable class btn //
        if(highestZoom === 3) {
          jQuery(this).addClass('inactiv_StylSp');
        }
        // you can zoom out so make sure style reps that //
        jQuery('#zoom-out').removeClass('inactiv_StylSp');
      });
      // zoom out //
      document.getElementById('zoom-out').addEventListener('click', function(ev){
        ev.preventDefault();
        jQuery('#interactive_infopopup').removeClass('showinteractive_infopop');
        panZoom.zoomOut();
        // Zoom vars //
        var currenrtZoom = panZoom.getZoom();
        var highestZoom = Math.round(currenrtZoom);
        var lowestZoom = currenrtZoom.toFixed(3);
        // Min zoom in hit - disable class btn //
        if(lowestZoom == 1.250) {
          jQuery(this).addClass('inactiv_StylSp');
        }
        // you can zoom in so make sure style reps that //
        jQuery('#zoom-in').removeClass('inactiv_StylSp');
      });
    }
  // END Draggable Zoom map //
  /* ====================== Specs Tab Changer ======================  */
  $('#spTabs li a:not(:first)').addClass('inactive');
  $('.sp_content_wrap').hide();
  $('.sp_content_wrap:first').show();
  $('.sp_content_wrapSpec').hide();
  $('.sp_content_wrapSpec:first').show();
  $('#spTabs li a').click(function(){
    var t = $(this).attr('href');
    $('#spTabs li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.sp_content_wrap').hide();
    $('.sp_content_wrapSpec').hide();
    $(t).fadeIn(800);
    return false;
  })
  if($(this).hasClass('inactive')){ //this is the start of our condition
    $('#spTabs li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.sp_content_wrap').hide();
    $('.sp_content_wrapSpec').hide();
    $(t).fadeIn(650);
  }
  // mobile version //
  $('#specSelection').change(function() {
    var t = $(this).val();
    $('#spTabs li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.sp_content_wrap').hide();
    $('.sp_content_wrapSpec').hide();
    $(t).fadeIn(800);
    return false;
    if($(this).hasClass('inactive')){ //this is the start of our condition
      $('#spTabs li a').addClass('inactive');
      $(this).removeClass('inactive');
      $('.sp_content_wrap').hide();
      $('.sp_content_wrapSpec').hide();
      $(t).fadeIn(650);
    }
  });
  /* ====================== Faqs Tabs Switcher ======================  */
  $('#jbtabs-switcher-nav li a:not(:first)').addClass('inactive');
  $('.jbtabs_container').hide();
  $('.jbtabs_container:first').show();
  $('#jbtabs-switcher-nav li a').click(function(){
    var t = $(this).attr('href');
    $('#jbtabs-switcher-nav li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.jbtabs_container').hide();
    $(t).fadeIn(800);
    return false;
  })
  if($(this).hasClass('inactive')){ //this is the start of our condition
    $('#jbtabs-switcher-nav li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.jbtabs_container').hide();
    $(t).fadeIn(800);
  }
  $('#accordion-switcher-nav-alt li a:not(:first)').addClass('inactive');
  $('.accordion_container_alt').hide();
  $('.accordion_container_alt:first').show();
  $('#accordion-switcher-nav-alt li a').click(function(){
    var t = $(this).attr('href');
    $('#accordion-switcher-nav-alt li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.accordion_container_alt').hide();
    $(t).fadeIn(800);
    return false;
  })
  if($(this).hasClass('inactive')){ //this is the start of our condition
    $('#accordion-switcher-nav-alt li a').addClass('inactive');
    $(this).removeClass('inactive');
    $('.accordion_container_alt').hide();
    $(t).fadeIn(800);
  }
  /* ====================== Accordion  ======================  */
  $('li.question').on('click',function () {
    if ($(this).next().hasClass("openedFaQ")) {
      $(this).removeClass('openedQuestion');
      $(this).next().slideUp().removeClass("openedFaQ");
    }
    else {
      $('li.question').removeClass('openedQuestion');
      $(this).addClass('openedQuestion');
      $('li.answer').slideUp().removeClass("openedFaQ");
      $(this).next().slideToggle(325).addClass('openedFaQ').siblings('li.answer').slideUp();
    }
    AOS.refresh();
  });
  /* Scroll to view */
  $(".sectionsDropdown ul li a").first().addClass("active");
  var addClassOnScroll = function () {
    var windowTop = $(window).scrollTop();
    $('section[id]').each(function (index, elem) {
      var offsetTop = $(elem).offset().top;
      var outerHeight = $(this).outerHeight(true);

      if( windowTop > (offsetTop - 50) && windowTop < ( offsetTop + outerHeight)) {
        var elemId = $(elem).attr('id');
        $(".sectionsDropdown ul li a.active").removeClass('active');
        $(".sectionsDropdown ul li a[href='#" + elemId + "']").addClass('active');
      }
    });
  };

  $(function () {
    $(window).on('scroll', function () {
      addClassOnScroll();
      AOS.refresh();
    });
  });

  // Add smooth scrolling to sections links
  $(".sectionsDropdown ul li a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });

  /* Active Class On Life with Villafont */
  $('.typeSelection').on('click', 'div', function() {
    $('div.activeBlgCat').removeClass('activeBlgCat');
    $(this).addClass('activeBlgCat');
  });

  /* Life with Villafont AJAX */
  $('.typeInner').click(function(e) {
    e.preventDefault();
    var category = $(this).data('category');

    $('#ajaxTakeoverWrappLl').addClass('show_takeoverAU');
    $.ajax({
      type: "post",
      url: theme_ajax.url,
      dataType: 'html',
      data: ({ action: 'filterBlog', category: category}),
      success: function(result){
        // remove loading //
        $('#ajaxTakeoverWrappLl').removeClass('show_takeoverAU');
        $('.blogResultsWrapp .contentCards').html(result);
      },
      error: function(result)
      {
        console.warn(result);
      }
    });
  });

  /* Find your home tabs */
  $('.topTtileWrapp .viewWrapp .viewBtn a:first').addClass('activeViewbtn');
  $('#find_homeDevsWrapp .views').hide();
  $('#find_homeDevsWrapp .views:first').show();
  $('.topTtileWrapp .viewWrapp a').click(function(){
    var t = $(this).attr('href');
    $('#find_homeDevsWrapp .views').hide();
    $(t).fadeIn(800);
    return false;
  })
  $('.topTtileWrapp .viewWrapp .viewBtn a').click(function() {
    $('.topTtileWrapp .viewWrapp .viewBtn a.activeViewbtn').removeClass('activeViewbtn');
    $(this).addClass('activeViewbtn');
  });

  // Location on the map
  $('.locPoint').click(function() {
    var activehotspot = $(this).attr('data-hotspot');
    if($(this).hasClass('activatedLocation_Vl')) {
      $(this).removeClass('activatedLocation_Vl');
      $('.locInfo').removeClass('locInfo_active');
    } else {
      $('.locPoint').removeClass('activatedLocation_Vl');
      $(this).addClass('activatedLocation_Vl');
      $('.locInfo').removeClass('locInfo_active');
      $('.'+activehotspot).addClass('locInfo_active');
    }
  });
  // End Location on the map

  // CF7 better errors //
  document.addEventListener( 'wpcf7invalid', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-warning');
    $('.wpcf7-response-output').addClass('alert-form alertform-danger');
  }, false );
  document.addEventListener( 'wpcf7spam', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
    $('.wpcf7-response-output').addClass('alert-form alertform-warning');
  }, false );
  document.addEventListener( 'wpcf7mailfailed', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
    $('.wpcf7-response-output').addClass('alert-form alertform-warning');
  }, false );
  document.addEventListener( 'wpcf7mailsent', function( event ) {
    $('.wpcf7-response-output').removeClass('alert-form alertform-danger');
    $('.wpcf7-response-output').removeClass('alert-form alertform-warning');
    $('.wpcf7-response-output').addClass('alert-form alertform-success');
  }, false );
});
